<template>
  <div class="alarm-item" :class="setClass(status)" ref="reference">
    <div class="alarm-scrollable">
      <div class="alarm-content">
        <div class="alarm-field">
          <small class="d-block text-muted">Sensor</small>
          <select class="form-control text-center" v-model="selectedSensor" @change="changeSelectedSensor">
            <option v-for="sensor in sensorOptions" :key="sensor.sensor_id" :value="sensor">
              {{ sensor.label }}
            </option>
          </select>
        </div>

        <div class="alarm-field" v-if="sensorType !== 3">
          <small class="d-block text-muted">Register</small>
          <select class="form-control text-center" v-model="selectedRegister" @change="setAlarmRegister">
            <option v-for="register in registerOptions" :key="register.address" :value="register">
              {{ register.address }}
            </option>
          </select>
        </div>

        <div class="alarm-field">
          <small class="d-block text-muted">Alarm Type</small>
          <select class="form-control text-center" @change="setAlarmType" :value="localAlarmType">
            <option v-for="type in alarmTypes" :key="type.alarm_type_id" :value="type.alarm_type_id">
              {{ type.type_pretty_name }}
            </option>
          </select>
        </div>

        <div v-if="alarm_type != 4 && alarm_type != 5" class="alarm-field">
          <small class="d-block text-muted">Alarm Set Point</small>
          <input type="number" step="0.01" @change="setAlarmValue" class="form-control text-center" :value="localAlarmValue" />
        </div>

        <div v-if="alarm_type == 4 || alarm_type == 5" class="alarm-field">
          <small v-if="hysteresisError" class="d-block text-danger">{{ hysteresisError }}</small>
          <small class="d-block text-muted">Set Point</small>
          <input type="number" step="0.01" @change="setAlarmValue" class="form-control text-center" :value="localAlarmValue" />
          <small class="d-block text-muted">Clear Point</small>
          <input type="number" step="0.01" @change="setHysteresisValue" class="form-control text-center" :value="localHysteresis" />
        </div>

        <div class="alarm-field">
          <small class="d-block text-muted">Notifications</small>
          <Multiselect
              class="form-control w-auto"
              style="background-color: var(--color-bg-sec);border-color: var(--color-border); padding:0 0 0 6px;"
              mode="multiple"
              :options="toWhom"
              v-model="selectedNotifications"
              label="displayname"
              track-by="displayname"
              value-prop="displayname"
              placeholder="123456"
              :multiple="true"
              :can-clear="false"
              :close-on-select="false"
              :close-on-deselect="false"
              :clear-on-select="false"
              :clear-on-deselect="false"
              :searchable="false"
              :show-labels="false"
              :hide-selected="false"
              :object="true"
              :append-to-body="true"
              @close="setNotifications"
          >
            <template v-slot:placeholder>
              <span style="cursor: pointer; text-align:center; flex-grow: 1">
                0 users selected
              </span>
            </template>
            <template v-slot:nooptions>
              <span style="cursor: pointer; text-align:center; flex-grow: 1">
                Please select a sensor first
              </span>
            </template>
            <template v-slot:multiplelabel="{ values }">
              <span style="cursor: pointer; text-align:center; flex-grow: 1">
                {{ values.length }} users selected
              </span>
            </template>
            <template v-slot:option="{ option }">
              <div class="d-flex align-items-center option-item">
                <i v-if="selectedNotifications?.some(selected => selected.displayname === option?.displayname)"
                   class="bi bi-check custom-checkmark" />
                {{ option?.displayname }}
              </div>
            </template>
          </Multiselect>
        </div>

        <div class="alarm-field">
          <small class="d-block text-muted">Action</small>
          <select class="form-control text-center" @change="setAlarmAction" :value="alarm_action">
            <option v-for="type in alarmActionTypes" :key="type.alarm_action_type_id" :value="type.alarm_action_type_id">
              {{ type.type_pretty_name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Ellipsis Button for Menu -->
    <div class="alarm-actions">
      <button @click="toggleMenu" class="ellipsis-button">⋮</button>
      <div v-if="showMenu" class="dropdown-menu">
        <span class="ellipsis-menu-item" @click="removeAlarm">Delete</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import {computed, ref} from "vue";
  import Multiselect from "@vueform/multiselect";

  export default {
    components: {
      Multiselect
  },
    props: {
      sensor_id: Number,
      alarm_type: String,
      alarm_value: Number,
      alarm_register: Number,
      alarm_action: String,
      hysteresis: Number,
      id: Number,
    },
    setup(props) {
      const store = useStore();
      const hysteresisError = ref('');
      const localAlarmValue = ref(props.alarm_value);
      const localHysteresis = ref(props.hysteresis);
      const localAlarmType = ref(props.alarm_type);
      
      const removeAlarm = function () {
        if (confirm('Are you sure you want to delete this alarm?')) {
          showMenu.value = false;
          store.commit('alarm/updateAlarmStatus', {
            id: props.id,
            status: 'deleted'
          });
          store.dispatch('gateway/findInfoOverview', {id: store.state.gateway.id});
        }
      }

      const setAlarmRegister = function() {
        store.commit('alarm/setRegister', {
          id: props.id,
          alarm_register: selectedRegister.value.address
        });
      }

      const validateHysteresis = (setPoint, clearPoint, alarmType) => {
        let sp = parseFloat(setPoint);
        let cp = parseFloat(clearPoint);
        let msg = '';
        if (alarmType == 4) {
          msg = sp < cp ? 'Clear Point must be less than Set Point' : '';
        } else {
          msg = sp > cp ? 'Set Point must be less than Clear Point' : '';
        }
        return msg;
      }

      const setAlarmValue = function(e) {
        hysteresisError.value = '';
        hysteresisError.value = validateHysteresis(e.target.value, localHysteresis.value, localAlarmType.value);

        if (hysteresisError.value === '') {
          clearError();
          const setPoint = e.target.value;
          localAlarmValue.value = setPoint;
          store.commit('alarm/setSetPoint', {
            id: props.id,
            alarm_set_point: setPoint,
          });
        } else {
          setError(hysteresisError.value);
          localAlarmValue.value = null;
        }
      }

      const setAlarmType = (e) => {
        clearError();
        localAlarmType.value = e.target.value;
        // if we change to a hysteresis type, we need to validate the values
        if (localAlarmType.value == 4 || localAlarmType.value == 5) {
          hysteresisError.value = validateHysteresis(localAlarmValue.value, localHysteresis.value, localAlarmType.value);
        }

        if (hysteresisError.value === '') {
          store.commit('alarm/setAlarmType', {
            id: props.id,
            alarm_type_id: localAlarmType.value,
          });
        } else {
          setError(hysteresisError.value);
          localAlarmValue.value = null;
          localHysteresis.value = null;
        }
      }

      const setHysteresisValue = (e) => {
        clearError();
        hysteresisError.value = validateHysteresis(localAlarmValue.value, e.target.value, localAlarmType.value);

        if (hysteresisError.value === '') {
          const hysteresis = e.target.value;
          localHysteresis.value = hysteresis;
          store.commit('alarm/setHysteresis', {
            id: props.id,
            hysteresis: hysteresis,
          });
        } else {
          setError(hysteresisError.value);
          localHysteresis.value = null;
        }
      };

      const selectedNotifications = ref(store.getters["alarm/selectedNotify"](props.id));

      const setNotifications = () => {
        // if the arrays are different, update the status
        store.commit('alarm/setSelectedNotifications', {
          id: props.id,
          selected_notify: selectedNotifications.value
        });
      };

      const setAlarmAction = (e) => {
        store.commit('alarm/setAlarmActionType', {
          id: props.id,
          alarm_action_type_id: e.target.value
        });
      };

      const calcSelected = (values) => values?.length || 0;

      const setClass = (status) => {
        if (status === 'existing') return;
        return status;
      }

      const setError = (error) => {
        store.commit('alarm/setAlarmError', {
          id: props.id,
          error: error
        });
      }

      const clearError = () => {
        hysteresisError.value = '';
        store.commit('alarm/clearAlarmError', {
          id: props.id,
        });
      }

      const sensorOptions = store.getters["MyView/getGateway"](store.state.gateway.id).nodes;
      const selectedSensor = ref(sensorOptions.find(sensor => sensor.id == props.sensor_id));
      const selectedRegister = ref(selectedSensor.value?.addresses.find(register => register.address == props.alarm_register));
      const changeSelectedSensor = async () => {
        let data = store.getters["alarm/alarm"](props.id);
        let toWhom = await store.dispatch('alarm/fetchNotificationOptions', selectedSensor.value.id);
        store.commit('alarm/updateAlarm', {
          ...data,
          id: props.id,
          sensor_id: selectedSensor.value.id,
          sensor_type_id: selectedSensor.value.sensor_type_id,
          alarm_register: null,
          register_type_id: null,
          toWhom: toWhom
        });
      };

      const showMenu = ref(false);
      const toggleMenu = () => showMenu.value = !showMenu.value;

      return {
        removeAlarm,
        setAlarmRegister,
        setAlarmValue,
        alarmTypes: computed(() => store.state.alarm.alarm_type_options),
        setAlarmType,
        setHysteresisValue,
        setNotifications,
        selectedNotifications,
        setAlarmAction,
        alarmActionTypes: computed(() => store.state.alarm.alarm_action_type_options),
        calcSelected,
        setClass,
        toWhom: computed(() => store.getters["alarm/toWhom"](props.id)),
        sensorOptions,
        changeSelectedSensor,
        sensorId: computed(() => selectedSensor.value?.sensor_id),
        sensorType: computed(() => selectedSensor.value?.sensor_type_id),
        registerOptions: computed(() => selectedSensor.value?.addresses),
        selectedSensor,
        selectedRegister,
        status: computed(() => store.getters["alarm/setAlarmStatus"](props.id)),
        showMenu,
        toggleMenu,
        hysteresisError,
        localAlarmValue,
        localHysteresis,
        localAlarmType,
      };
    },
  };
</script>
<style scoped>
li {
  cursor: pointer;
}
div.new  {
  border: 3px solid #41b883;
}

div.updated {
  border: 3px solid orange;
}

div.deleted {
  text-decoration: line-through;
  color: var(--color-txt);
  opacity: 0.5;
  pointer-events: none;
}

.text-muted {
  color: var(--color-txt)!important;
}

.alarm-scrollable {
  overflow-x: auto;
  flex-grow: 1;
}

.alarm-content {
  display: flex;
  align-items: center;
}

.alarm-content > * {
  flex-shrink: 0;
  margin-left: 12px;
}

.alarm-actions {
  position: relative;
  flex-shrink: 0;
}

.ellipsis-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding-left: 8px;
  color: var(--color-txt);
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background: var(--color-bg-sec);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 1000;
  display: block;
  min-width: 75px;
  white-space: nowrap;
}

small {
  margin: 0!important;
  padding: 0!important;
  font-size: 0.75em;
  color: var(--color-txt);
}

.alarm-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: var(--color-bg);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.5s ease, color 0.5s ease;
  overflow-y: visible;
  position: relative;
  white-space: nowrap;
}

.alarm-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: fit-content;
}

.alarm-field >select, .alarm-field >input {
  background: var(--color-bg-sec);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  color: var(--color-txt);
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
}

.ellipsis-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--color-txt);
  transition: background 0.5s ease, color 0.5s ease;
}

.alarm-actions {
  position: relative;
}

.ellipsis-menu-item {
  cursor: pointer;
  padding: 4px;
  color: var(--color-txt);
}

.ellipsis-menu-item:hover {
  color: var(--color-sec);
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
