<template>
  <div ref="resizeRef">
    <svg ref="svgRef" height="400" width="1200">
    </svg>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {
  select,
  line,
  scaleTime,
  scaleLinear,
  min,
  max,
  axisBottom,
  axisLeft,
  brush,
  area
} from 'd3';
import useResizeObserver from '@/components/shared/charts/resizeObserver';
import {useTippy} from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import {useStore} from "vuex";

export default {
  name: 'D3Chart',
  props: [
      'data',
      'ai',
  ],
  setup(props) {
    const store = useStore();

    const svgRef = ref(null);
    const { resizeRef, resizeState } = useResizeObserver();

    // chart colors
    const sensorClr = '#F7941D';
    const aiClr = '#1900ff';
    const timeoutClr = 'red';

    // tooltip element ref
    const ttElem = ref();

    const chartType = computed(() => store.state.chart.type.group_gateway_sensor);

    // time format options
    const timeOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };

    // find the discrete steps between the x values (assumes the x values are sorted)
    const calcSteps = (arr) => {
      let smallestInterval = undefined;
      for (let i = 0; i < arr.length - 1; i++) {
        let diff = arr[i+1] - arr[i];
        if (smallestInterval === undefined || (diff < smallestInterval && diff > 0)) {
          smallestInterval = diff;
        }
      }
      return (max(arr) - min(arr)) / smallestInterval;
    };

    const alignment = (deg) => {
      // N = ~190
      if (deg >= 190 && deg < 212.5) return 'N';
      if (deg >= 212.5 && deg < 235) return 'NNE';
      if (deg >= 235 && deg < 257.5) return 'NE';
      if (deg >= 257.5 && deg < 280) return 'ENE';
      if (deg >= 280 && deg < 302.5) return 'E';
      if (deg >= 302.5 && deg < 325) return 'ESE';
      if (deg >= 325 && deg < 347.5) return 'SE';
      if (deg >= 347.5 && deg < 360) return 'SSE';
      if (deg >= 0 && deg < 10) return 'SSE';
      if (deg >= 10 && deg < 32.5) return 'S';
      if (deg >= 32.5 && deg < 55) return 'SSW';
      if (deg >= 55 && deg < 77.5) return 'SW';
      if (deg >= 77.5 && deg < 100) return 'WSW';
      if (deg >= 100 && deg < 122.5) return 'W';
      if (deg >= 122.5 && deg < 145) return 'WNW';
      if (deg >= 145 && deg < 167.5) return 'NW';
      if (deg >= 167.5 && deg < 190) return 'NNW';
      return deg;
    }

    const drawSensorChart = (data, {xScale, yScale, padding, bar}) => {
      if (xScale == null || yScale == null) {
        return;
      }

      const svg = select(svgRef.value);
      let {width, height} = resizeState.dimensions;

      if (width === 0 || height === 0) {
        let svgRect = svgRef.value.getBoundingClientRect();
        width = svgRect.width;
        height = svgRect.height;
      }

      let units = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_units')?.attribute_value;
      units = units == 0 ? '' : units;

      if (width - padding.left < 0 || height - padding.bottom < 0) {
        return;
      }

      switch (chartType.value) {
        case 'line': {
          // line function
          const lineFunc = line()
              .x((d) => xScale(d.x))
              .y((d) => yScale(d.y));

          // render line path
          svg.selectAll('path.sensor')
              .data([data])
              .join('path')
              .transition()
              .attr('class', 'sensor')
              .attr('d', lineFunc)
              .attr('stroke-width', 1)

          // circles
          svg.selectAll('circle.sensor')
              .data(data)
              .join('circle')
              .on('mouseenter', (e, d) => {
                select(e.target).attr('r', 7);
                ttElem.value = e.target;
                useTippy(ttElem, {
                  arrow: true,
                  allowHTML: true,
                  content: () => `${d.x.toLocaleString([], timeOptions)}<br/>${d.y} ${units}`,
                  inlinePositioning: true,
                  showOnCreate: true,
                })
              })
              .on('mouseout', e => {
                select(e.target).attr('r', 5);
              })
              .transition()
              .attr('class', 'sensor')
              .attr('cx', d => xScale(d.x))
              .attr('cy', d => yScale(d.y))
              .attr('r', 5)
              .style('fill', sensorClr)
              .style('stroke', sensorClr);
          break;
        }
        case 'bar': {
          // sensor bars
          svg.selectAll('rect.sensor')
            .data(data)
            .join('rect')
            .on('mouseenter', (e, d) => {
              select(e.target).attr('fill', sensorClr);
              ttElem.value = e.target;
              useTippy(ttElem, {
                arrow: true,
                allowHTML: true,
                content: () => `${d.x.toLocaleString([], timeOptions)}<br/>${d.y} ${units}`,
                inlinePositioning: true,
                showOnCreate: true,
              });
            })
            .on('mouseout', e => {
              select(e.target).attr('fill', sensorClr);
            })
            .transition()
            .attr('class', 'sensor')
            .attr('x', d => xScale(d.x) - (bar.width / 2.0))
            .attr('y', d => yScale(d.y))
            .attr('width', bar.width)
            .attr('height', d => height - padding.bottom - yScale(d.y))
            .style('fill', sensorClr)
            .style('stroke', sensorClr)
          break;
        }
        case 'wind': {
          let arrowLine = line();
          let arrowDef = [[10, 22], [12, 22], [12, 10], [18, 10], [10, 0], [2, 10], [8, 10], [8, 22], [10, 22]];

          let arrowScale = max([data.length * 0.025, 1.25]);
          arrowDef.forEach(point => {
            point[0] = point[0] / arrowScale;
            point[1] = point[1] / arrowScale;
          });

          svg.selectAll('path.arrow')
            .data(data)
            .join('path')
            .on('mouseenter', (e, d) => {
              ttElem.value = e.target;
              useTippy(ttElem, {
                arrow: true,
                allowHTML: true,
                content: () => `${d.x.toLocaleString([], timeOptions)}<br/>Speed: ${d.y} ${units}<br/>Direction: ${alignment(d.z)}`,
                inlinePositioning: true,
                showOnCreate: true,
              })
            })
            .on('mouseout', e => {
              select(e.target).attr('fill', sensorClr);
              select(e.target).attr('stroke', sensorClr);
            })
            .transition()
            .attr('class', 'arrow')
            .attr('d', arrowLine(arrowDef))
            .attr('transform', d => `translate(${xScale(d.x)}, ${yScale(d.y)}) rotate(${d.z - 200})`)
            .attr('fill', sensorClr)
            .attr('stroke', sensorClr)
          break;
        }
        default: {
          console.error('Invalid chart type');
        }
      }
    }

    const updateChartAi = (aiData, {xScale, yScale, bar}) => {
      const svg = select(svgRef.value);
      let units = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_units')?.attribute_value;
      if (units == 0 || units == null) units = '';

      const percentileAreas = [
        { lower: '0.1', upper: '0.9', opacity: 0.4, confidence: '90%' },
        { lower: '0.2', upper: '0.8', opacity: 0.5, confidence: '70%' },
        { lower: '0.3', upper: '0.7', opacity: 0.6, confidence: '50%' },
        { lower: '0.4', upper: '0.6', opacity: 0.7, confidence: '30%' }
      ];

      const getAiToolTip = (closestDataPoint, units) => {
        let timestamp = closestDataPoint.x.toLocaleString([], timeOptions);
        let confStr = '';
        for (let p of percentileAreas) {
          let lower = closestDataPoint.y[p.lower];
          let upper = closestDataPoint.y[p.upper];
          confStr += `${p.confidence}: ${lower.toFixed(2)} - ${upper.toFixed(2)} ${units}<br/>`;
        }
        return `${timestamp}<br/>Mean: ${closestDataPoint.y['0.5'].toFixed(2)}<br/>${confStr}`;
      }

      switch (chartType.value) {
        case 'line': {
          percentileAreas.forEach(({lower, upper, opacity}) => {
            const areaFunc = area()
              .x((d) => xScale(d.x))
              .y0((d) => isNaN(d.y[lower]) ? yScale.range()[0] : yScale(d.y[lower]))
              .y1((d) => isNaN(d.y[upper]) ? yScale.range()[0] : yScale(d.y[upper]));

            const className = `ai-fan-${lower.replace('.', '-')}-${upper.replace('.', '-')}`;

            svg.selectAll(`.area.${className}`)
              .data([aiData])
              .join('path')
              .attr('class', className)
              .attr('d', areaFunc)
              .attr('fill', aiClr)
              .attr('stroke', 'none')
              .attr('opacity', opacity)
              .on('mouseenter', (e, d) => {
                ttElem.value = e.target;
                let closest = d.reduce((prev, curr) => Math.abs(curr.x - xScale.invert(e.offsetX)) < Math.abs(prev.x - xScale.invert(e.offsetX)) ? curr : prev);
                useTippy(ttElem, {
                  arrow: true,
                  allowHTML: true,
                  content: () => getAiToolTip(closest, units),
                  inlinePositioning: true,
                  showOnCreate: true,
                });
              })
              .on('mousemove', (e, d) => {
                let closest = d.reduce((prev, curr) => Math.abs(curr.x - xScale.invert(e.offsetX)) < Math.abs(prev.x - xScale.invert(e.offsetX)) ? curr : prev);
                useTippy(ttElem, {
                  arrow: true,
                  allowHTML: true,
                  content: () => getAiToolTip(closest, units),
                  inlinePositioning: true,
                  showOnCreate: true,
                });
              });
          });

          const meanLineFunc = line()
              .x((d) => xScale(d.x))
              .y((d) => yScale(d.y['0.5']));

          svg.selectAll('path.ai.mean')
              .data([aiData])
              .join('path')
              .attr('class', 'ai.mean')
              .attr('d', meanLineFunc)
              .attr('stroke', 'white')
              .attr('stroke-width', 1.5)
              .attr('stroke-dasharray', '5,5')
              .attr('fill', 'none');
          break;
        }
        case 'bar': {
          percentileAreas.forEach(({lower, upper, opacity}) => {
            svg.selectAll('rect.ai')
              .data(aiData)
              .join('rect')
              .on('mouseenter', (e, d) => {
                select(e.target).attr('fill', aiClr);
                ttElem.value = e.target;
                useTippy(ttElem, {
                  arrow: true,
                  allowHTML: true,
                  content: () => getAiToolTip(d, units),
                  inlinePositioning: true,
                  showOnCreate: true,
                });
              })
              .on('mouseout', e => {
                select(e.target).attr('fill', aiClr);
              })
              .transition()
              .attr('class', 'ai')
              .attr('x', d => xScale(d.x) - (bar.width/2.0))
              .attr('y', d => yScale(d.y[upper]))
              .attr('width', bar.width)
              .attr('height', d => yScale(d.y[lower]) - yScale(d.y[upper]))
              .style('fill', aiClr)
              .style('stroke', aiClr)
              .style('opacity', opacity)
          });
          break;
        }
        case 'wind': {
          let arrowLine = line();
          let arrowDef = [[10, 22], [12, 22], [12, 10], [18, 10], [10, 0], [2, 10], [8, 10], [8, 22], [10, 22]];

          // ai arrows
          svg.selectAll('path.aiarrow')
            .data(aiData)
            .join('path')
            .on('mouseenter', (e, d) => {
              ttElem.value = e.target;
              useTippy(ttElem, {
                arrow: true,
                allowHTML: true,
                content: () => `${d.x.toLocaleString([], timeOptions)}<br/>Speed: ${d.y['0.5']} ${units}<br/>Direction: ${alignment(d.z['0.5'])}`,
                inlinePositioning: true,
                showOnCreate: true,
              })
            })
            .on('mouseout', e => {
              select(e.target).attr('fill', aiClr);
              select(e.target).attr('stroke', aiClr);
            })
            .transition()
            .attr('class', 'aiarrow')
            .attr('d', arrowLine(arrowDef))
            .attr('transform', d => `translate(${xScale(d.x)}, ${yScale(d.y['0.5'])}) rotate(${d.z['0.5']-200})`)
            .attr('fill', aiClr)
            .attr('stroke', aiClr);
          break;
        }
        default: {
          console.error('Invalid chart type');
        }
      }
    };

    const drawTimeouts = (data, {xScale, yScale}) => {
      const svg = select(svgRef.value);

      svg.selectAll('line.timeout')
          .data(data)
          .join('line')
          .attr('class', 'timeout')
          .attr('x1', (d) => xScale(d.x))
          .attr('x2', (d) => xScale(d.x))
          .attr('y1', yScale.range()[0])
          .attr('y2', yScale.range()[1])
          .attr('stroke-width', 1)
          .attr('stroke-dasharray', '5,2')
          .attr('opacity', 0.7)
          .on('mouseenter', (e, d) => {
            ttElem.value = e.target;
            select(e.target).attr('stroke-width', 4);
            useTippy(ttElem, {
              arrow: true,
              allowHTML: true,
              content: () => `Timeout: ${d.x.toLocaleString([], timeOptions)}`,
              inlinePositioning: true,
              showOnCreate: true,
            });
          })
          .on('mouseout', e => {
            let target = select(e.target);
            target.attr('stroke-width', 1);
          });
    };

    const drawAlarms = (data, {xScale, yScale}) => {
      const svg = select(svgRef.value);
      svg.selectAll('line.alarm')
          .data(data)
          .join('line')
          .attr('class', 'alarm')
          .attr('x1', xScale.range()[0])
          .attr('x2', xScale.range()[1])
          .attr('y1', (d) => yScale(d.alarm_set_point))
          .attr('y2', (d) => yScale(d.alarm_set_point))
          .attr('stroke-width', 3)
          .attr('stroke-dasharray', '5,2')
          .attr('opacity', 0.7)
          .on('mouseenter', (e, d) => {
            ttElem.value = e.target;
            select(e.target).attr('stroke-width', 4);
            useTippy(ttElem, {
              arrow: true,
              allowHTML: true,
              content: () => `Alarm set at ${d.alarm_set_point} ${d.alarm_set_point_2?`and ${d.alarm_set_point_2} `:''}`,
              inlinePositioning: true,
              showOnCreate: true,
            });
          })
          .on('mouseout', e => {
            let target = select(e.target);
            target.attr('stroke-width', 3);
          });
    }

    const drawLegend = (presentData, {height, width}) => {
      const svg = select(svgRef.value);
      let legend = svg.append('g').attr('class', 'legend');

      const legendPadding = 5;
      const legendWidth = 20;
      const legendHeight = 20;
      const textOffset = 200;

      const addLegend = (x, y, color, text) => {
        legend.append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("height", legendHeight)
            .attr("width", legendWidth)
            .style("fill", color);
        legend.append("text")
            .attr("x", x + legendWidth + legendPadding)
            .attr("y", y + legendHeight / 2)
            .attr("alignment-baseline", "middle")
            .text(text)
            .style("font-size", "15px");
      };

      // Determine which data is present and create an array of legend items
      let legends = [];
      if (presentData.sensor) {
        legends.push({ color: sensorClr, text: 'Sensor Value' });
      }
      if (presentData.ai) {
        legends.push({ color: aiClr, text: 'AI Prediction' });
      }
      if (presentData.unresponsive) {
        legends.push({ color: timeoutClr, text: 'Timeouts & Errors' });
      }
      if (presentData.alarm) {
        legends.push({ color: 'deepskyblue', text: 'Alarms' });
      }

      // Calculate positions for each legend item
      const startX = width / 2 - (legends.length * (legendWidth + textOffset + legendPadding)) / 2;
      const startY = height - 25;
      legends.forEach((legend, index) => {
        const x = startX + index * (legendWidth + textOffset + legendPadding);
        addLegend(x, startY, legend.color, legend.text);
      });
    };

    const cleanupOldChart = () => {
      const svg = select(svgRef.value);
      svg.selectAll('svg > *').remove();
    };

    const onResize = () => {
      cleanupOldChart();
      drawCharts();
    };

    const chartInit = ({xMin, xMax, yMin, yMax, steps}) => {
      const svg = select(svgRef.value);
      let {width, height} = resizeState.dimensions;

      if (width === 0 || height === 0) {
        let svgRect = svgRef.value.getBoundingClientRect();
        width = svgRect.width;
        height = svgRect.height;
      }

      if ([xMin, xMax, yMin, yMax].every(i => i == null || isNaN(i))) {
        svg.append('text')
            .text('No Data Found')
            .attr('class', 'no-data')
            .attr('x', width / 2 - 75)
            .attr('y', height / 2)

        // expand the chart options if there is no data
        document.querySelector('accordion-button')?.classList.remove('collapsed');

        return {xScale: null, yScale: null, padding: null};
      }

      let units = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_units')?.attribute_value;
      let name = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_name')?.attribute_value;
      units = units == 0 ? '' : units;
      name = name == 0 ? '' : name;
      let ylabelBuffer = units != null || name != null ? 50 : 30;

      // adjust left according to the number of digits in the max value
      let paddingLeft = ylabelBuffer + parseInt(yMax).toString().length * 5;
      const paddingBtm = 50;

      // scales
      let xScale;
      let barWidth;
      if (chartType.value === 'bar') {
        // calculate bar width
        barWidth = Math.abs((width - paddingLeft) / (steps + steps * 0.25));
        xScale = scaleTime()
            .domain([xMin, xMax])
            .range([paddingLeft + barWidth/2.0 + 5, width - barWidth/2.0 - 5])
      } else {
        xScale = scaleTime()
            .domain([xMin, xMax])
            .range([paddingLeft + 5, width - 5]);
      }
      let yRatio = yMax * 0.05;
      let yScale = scaleLinear()
          .domain([yMin - yRatio, yMax + yRatio])
          .range([height - paddingBtm, 0]);

      // axes
      let xAxis = axisBottom(xScale);
      svg.selectAll('g.xaxis').remove();
      svg.append('g')
          .attr('class', 'xaxis')
          .style('transform', `translateY(${height - paddingBtm}px)`)
          .call(xAxis);

      let yAxis = axisLeft(yScale);
      svg.selectAll('g.yaxis').remove();
      svg.append('g')
          .attr('class', 'yaxis')
          .style('transform', `translateX(${paddingLeft}px)`)
          .call(yAxis);

      // grid lines
      svg.selectAll('line.grid')
          .data(yScale.ticks())
          .enter()
          .append('line')
          .attr('class', 'grid')
          .attr('x1', paddingLeft)
          .attr('x2', width)
          .attr('y1', d => yScale(d) + 0.5)
          .attr('y2', d => yScale(d) + 0.5)
          .style('stroke-width', 0.5)

      // y axis label
      svg.append('text')
          .attr('class', 'yaxis-label')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0)
          .attr('x', 0 - (height / 2))
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .text(`${name} ${units}`);

      // brushing behavior
      svg.call(brush()
        .extent([[paddingLeft, 0], [width, height - paddingBtm]])
        .on('end', ({selection}) => {
          if (selection) {
            const [[x0, y0], [x1, y1]] = selection;

            let sensorElem, aiElem;
            if (chartType.value === 'line') {
              sensorElem = 'circle.sensor';
              aiElem = 'path.ai';
            } else if (chartType.value === 'bar') {
              sensorElem = 'rect.sensor';
              aiElem = 'rect.ai';
            } else if (chartType.value === 'wind') {
              sensorElem = 'path.arrow';
              aiElem = 'path.aiarrow';
            } else {
              console.error('Invalid chart type');
              return;
            }

            let values = svg.selectAll(sensorElem)
                .filter(d => x0 <= xScale(d.x) && xScale(d.x) <= x1 && y0 <= yScale(d.y) && yScale(d.y) <= y1)
                .data();
            let aiValues = svg.selectAll(aiElem)
                .filter(d => x0 <= xScale(d.x) && xScale(d.x) <= x1 && y0 <= yScale(d.y) && yScale(d.y) <= y1)
                .data();
            let timeoutValues = svg.selectAll('line.timeout')
                .filter(d => x0 <= xScale(d.x) && xScale(d.x) <= x1)
                .data();
            let alarmValues = svg.selectAll('line.alarm')
                .filter(d => y0 <= yScale(d.y) && yScale(d.y) <= y1)
                .data();

            if (values?.length === 0 && aiValues?.length === 0 && timeoutValues?.length === 0) {
              console.log('No data selected');
              return;
            }

            cleanupOldChart();

            // get the scales for the concatenated data
            let bounds = getBounds([...values, ...aiValues, ...timeoutValues, ...alarmValues]);
            let chartProps = chartInit(bounds);

            if (aiValues.length > 0) {
              updateChartAi(aiValues, chartProps);
            }
            if (timeoutValues.length > 0) {
              drawTimeouts(timeoutValues, chartProps);
            }
            if (alarmValues.length > 0) {
              drawAlarms(alarmValues, chartProps);
            }

            drawSensorChart(values, chartProps);
            drawLegend({
              sensor: values.length > 0,
              ai: aiValues.length > 0,
              unresponsive: timeoutValues.length > 0,
              alarm: alarmValues.length > 0
            }, chartProps);
            svg.call(brush().clear);
          }
        }));

      // double click to reset chart
      svg.on('dblclick', () => {
        cleanupOldChart();
        drawCharts();
      });

      return {xScale, yScale, height, width, padding: {left: paddingLeft, bottom: paddingBtm}, bar: {width: barWidth}};
    };

    const getBounds = (data) => {
      if (!data) {
        return { xMin: null, xMax: null, yMin: null, yMax: null };
      }
      const filteredData = data?.filter(d => d.y != null && !isNaN(d.y));
      const xMin = min(data, d => d?.x);
      const xMax = max(data, d => d?.x);
      const steps = calcSteps(data.map(d => d.x));
      const yMin = min(filteredData, d => d?.y);
      const yMax = max(filteredData, d => d?.y);
      return { xMin, xMax, yMin, yMax, steps };
    };

    const drawCharts = () => {
      const combineData = () => {
        let combinedData = [];
        let presentData = {};
        if (props.data) {
          presentData.sensor = true;
          combinedData = [...props.data];
        }
        if (props.ai && store.state.chart.showing_predictions) {
          presentData.ai = true;
          combinedData = [...combinedData, ...props.ai];
        }
        if (store.state.chart.has_unresponsive_data.group_gateway_sensor && store.state.chart.filters.showingTimeouts) {
          presentData.unresponsive = true;
          let unresponsiveData = store.state.chart.unresponsive_data.group_gateway_sensor;
          combinedData = [...combinedData, ...unresponsiveData.map(i => ({ x: i.timestamp, y: i.sensor_data_value }))];
        }

        if (store.state.alarm.alarm_set?.length > 0 && store.state.chart.filters.showingAlarms) {
          presentData.alarm = true;
          let alarmData = store.state.alarm.alarm_set.filter(a => a.sensor_id == store.state.apgList.List.sensor_info?.sensor_id);
          combinedData = [...combinedData, ...alarmData.map(a => ({ x: null, y: a.alarm_set_point, data: a }))];
        }

        return {data: combinedData, presentData};
      };

      // get data bounds
      const {data, presentData} = combineData();
      const bounds = getBounds(data);
      const chartProps = chartInit(bounds);

      if (chartProps.xScale == null || chartProps.yScale == null) {
        return;
      }

      if (presentData.ai) {
        updateChartAi(props.ai, chartProps);
      }

      if (presentData.unresponsive) {
        drawTimeouts(store.state.chart.unresponsive_data.group_gateway_sensor, chartProps);
      }

      if (presentData.alarm) {
        let sensorId = store.state.sensor.id;
        let register = store.state.register.address;
        let alarmData = store.state.alarm.alarm_set.filter(d => d.sensor_id == sensorId && d.alarm_register == register);
        drawAlarms(alarmData, chartProps);
      }

      drawSensorChart(props.data, chartProps);
      drawLegend(presentData, chartProps);
    };

    // watch for when the chart tab is active
    const tabObserver = ref(null);

    onMounted(() => {
      window.addEventListener('resize', onResize);
      drawCharts();

      // add observer to the 'chart' tab
      const tabElement = document.querySelector('#home-tab');
      if (!tabElement) {
        console.error('Could not find the tab element');
        return;
      }
      const observer = new MutationObserver(() => {
        if (tabElement.classList.contains('active')) {
          onResize();
        }
      });
      observer.observe(tabElement, { attributes: true });
      tabObserver.value = observer;
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);

      // Remove the 'chart' tab observer
      tabObserver.value?.disconnect();
    });

    const aiToggle = computed(() => store.state.chart.showing_predictions);
    const timeoutToggle = computed(() => store.state.chart.filters.showingTimeouts);
    const alarmToggle = computed(() => store.state.chart.filters.showingAlarms);

    // Watch for changes in `data` and `is_loading`
    watch([() => props.data, () => store.state.settings.is_loading], ([newData, isLoading]) => {
      if (newData && !isLoading) {
        cleanupOldChart();
        drawCharts();
      }
    });

    // Watch for changes in `ai` and `is_loading`
    watch([() => props.ai, () => store.state.settings.is_loading], ([newAi, isLoading]) => {
      if (newAi && !isLoading) {
        cleanupOldChart();
        drawCharts();
      }
    });

    // Watch for changes in `aiToggle` and `is_loading`
    // eslint-disable-next-line no-unused-vars
    watch([() => aiToggle.value, () => store.state.settings.is_loading], ([newAiToggle, isLoading]) => {
      if (!isLoading) {
        cleanupOldChart();
        drawCharts();
      }
    });

    // Watch for changes in `timeoutToggle` and `is_loading`
    // eslint-disable-next-line no-unused-vars
    watch([() => timeoutToggle.value, () => store.state.settings.is_loading], ([newTimeoutToggle, isLoading]) => {
      if (!isLoading) {
        cleanupOldChart();
        drawCharts();
      }
    });

    // Watch for changes in `alarmToggle` and `is_loading`
    // eslint-disable-next-line no-unused-vars
    watch([() => alarmToggle.value, () => store.state.settings.is_loading], ([newAlarmToggle, isLoading]) => {
      if (!isLoading) {
        cleanupOldChart();
        drawCharts();
      }
    });

    // Watch for changes in `chartType` and `is_loading`
    // eslint-disable-next-line no-unused-vars
    watch([() => chartType.value, () => store.state.settings.is_loading], ([newChartType, isLoading]) => {
      if (!isLoading) {
        cleanupOldChart();
        drawCharts();
      }
    });

    return {
      svgRef,
      resizeRef,
      ttElem
    };
  },
}
</script>
<style>
svg {
  /* important for responsiveness */
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible;
}

line, path.sensor {
  stroke: var(--color-txt);
}
text {
  fill: var(--color-txt)!important;
}
g.legend {
  fill: var(--color-txt);
}
line.timeout {
  stroke: red;
}

line.alarm {
  stroke: deepskyblue;
}
</style>
